var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "prizeFrame" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "dialogDrag",
              rawName: "v-dialogDrag",
              value: true,
              expression: "true",
            },
          ],
          attrs: {
            title: "添加奖品",
            visible: _vm.OpenPrize,
            "before-close": _vm.close,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.OpenPrize = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "75px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "奖品名称" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      type: "text",
                      placeholder: "最多可填写8个字",
                      maxlength: "8",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.text,
                      callback: function ($$v) {
                        _vm.text = $$v
                      },
                      expression: "text",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "奖品名称" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.form.jpType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "jpType", $$v)
                          },
                          expression: "form.jpType",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("积分"),
                        ]),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("余额"),
                        ]),
                        _c("el-radio", { attrs: { label: 3 } }, [
                          _vm._v("优惠券"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "CouponTable marT10 marB10" },
                      [
                        _vm.form.jpType === 1
                          ? _c("el-input")
                          : _vm.form.jpType === 2
                          ? _c("el-input")
                          : _vm._e(),
                        _vm.form.jpType === 3
                          ? _c(
                              "el-table",
                              {
                                ref: "couponTable",
                                staticClass: "couponTable",
                                attrs: {
                                  data: _vm.form.couponLargessItem,
                                  border: "",
                                  "max-height": "300",
                                  "row-key": "couponCaseId",
                                },
                                on: {
                                  "cell-mouse-enter": _vm.cellMouseEnter,
                                  "cell-mouse-leave": _vm.cellMouseLeave,
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { align: "center", width: "80" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            scope.row.hoverRow
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-circle-plus operatePush",
                                                })
                                              : _vm._e(),
                                            scope.row.hoverRow
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-remove operateDel",
                                                })
                                              : _vm._e(),
                                            !scope.row.hoverRow
                                              ? _c("div", [
                                                  _vm._v(
                                                    _vm._s(scope.$index + 1)
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1315207511
                                  ),
                                }),
                                _c("el-table-column", {
                                  key: "couponCaseName",
                                  attrs: {
                                    label: "优惠券名称",
                                    align: "center",
                                    prop: "couponCaseName",
                                    "show-overflow-tooltip": "",
                                    width: "180",
                                  },
                                }),
                                _c("el-table-column", {
                                  key: "couponCaseTypeName",
                                  attrs: {
                                    label: "优惠券类型",
                                    align: "center",
                                    prop: "couponCaseTypeName",
                                    width: "180",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("dict-tag", {
                                              attrs: {
                                                options:
                                                  _vm.dict.type
                                                    .coupon_case_type,
                                                value: scope.row.couponCaseType,
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2467637763
                                  ),
                                }),
                                _c("el-table-column", {
                                  key: "expirationDate",
                                  attrs: {
                                    label: "有效期时间",
                                    align: "center",
                                    prop: "expirationDate",
                                    "show-overflow-tooltip": true,
                                    width: "180",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-form-item", { attrs: { label: "奖品图片" } }, [
                _c(
                  "div",
                  { staticClass: "x-end" },
                  [
                    _c(
                      "el-upload",
                      {
                        staticClass: "avatar-uploader",
                        attrs: {
                          action: _vm.uploadUrl,
                          "show-file-list": false,
                          "on-success": _vm.handleLogoSuccess,
                          "before-upload": _vm.beforeAvatarUpload,
                        },
                      },
                      [
                        _vm.form.goodsUrl
                          ? _c("img", {
                              staticClass: "avatar",
                              attrs: { src: _vm.form.goodsUrl },
                            })
                          : _c("i", {
                              staticClass: "el-icon-plus avatar-uploader-icon",
                            }),
                        _vm.form.goodsUrl
                          ? _c("div", { staticClass: "updateImg" }, [
                              _vm._v("更换图片"),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c("div", { staticClass: "picBtn marL10" }, [
                      _vm._v("恢复默认图"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "tag", staticStyle: { color: "#a4a5a6" } },
                  [_vm._v(" 建议尺寸100*100px, 支持jpg、png、jpeg格式。 ")]
                ),
              ]),
              _c("el-form-item", { attrs: { label: "发放总量" } }, [
                _c(
                  "div",
                  { staticClass: "x-f" },
                  [_c("el-input"), _c("div", [_vm._v("份")])],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.OpenPrize = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }