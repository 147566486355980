<template>
  <!-- 抽奖活动明细 -->
  <div class="luckDrawDetail">
    <!-- 头部定位区域 -->
    <head-tool>
      <!-- <template #left>
        <div class="headTitle">{{ `抽奖活动` }}</div>
      </template> -->
      <template #right>
        <el-button type="primary" size="mini">保存</el-button>
        <el-button type="primary" size="mini">保存并退出</el-button>
        <el-dropdown split-button size="mini">
          审核
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>反审核</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button size="mini">退出</el-button>
      </template>
    </head-tool>
    <el-form label-width="100px">
      <div class="main">
        <!-- 抽奖左边图片 -->
        <div class="leftImg">
          <h3>抽奖样式</h3>
          <div class="pig">
            <div class="turnplate">
              <div class="one">
                <div>
                  <img :src="defaultPig" alt="加载失败" />
                  <div class="name">111</div>
                </div>
                <div>
                  <img :src="defaultPig" alt="加载失败" />
                  <div class="name">111</div>
                </div>
                <div>
                  <img :src="defaultPig" alt="加载失败" />
                  <div class="name">111</div>
                </div>
              </div>
              <div class="two">
                <div>
                  <img :src="defaultPig" alt="加载失败" />
                  <div class="name">111</div>
                </div>
                <div>
                  <img :src="defaultPig" alt="加载失败" />
                  <div class="name">111</div>
                </div>
              </div>
              <div class="three">
                <div>
                  <img :src="defaultPig" alt="加载失败" />
                  <div class="name">111</div>
                </div>
                <div>
                  <img :src="defaultPig" alt="加载失败" />
                  <div class="name">111</div>
                </div>
                <div>
                  <img :src="defaultPig" alt="加载失败" />
                  <div class="name">111</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 抽奖右边信息 -->
        <div class="rightInfo">
          <el-scrollbar>
            <!-- 基本信息 -->
            <comCard :title="`基本信息`">
              <template #content>
                <div class="basicInfo">
                  <el-form-item label="活动编号" prop="promoNo">
                    <el-input
                      v-model="form.promoNo"
                      disabled
                      placeholder="自动生成"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="活动名称" prop="promoName">
                    <el-input v-model="form.promoName"></el-input>
                  </el-form-item>
                  <div class="x-f">
                    <el-form-item label="活动日期" prop="datetime">
                      <el-date-picker
                        v-model="form.datetime"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </div>
                  <div class="x-x">
                    <el-form-item label="适用对象" prop="vipLevelRangeTypeArr">
                      <el-checkbox-group
                        v-model="form.vipLevelRangeTypeArr"
                        @change="handleCheckVip"
                      >
                        <el-checkbox label="0">全部会员</el-checkbox>
                        <el-checkbox label="3">非会员</el-checkbox>
                        <el-checkbox label="1">全部会员+排除</el-checkbox>
                        <el-checkbox label="2">指定会员</el-checkbox>
                        <el-checkbox label="4">生日会员</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                    <el-select
                      v-model="form.vipBirthdayType"
                      class="selectVipBD"
                      v-if="showVipBD"
                    >
                      <el-option
                        :label="dict.label"
                        v-for="dict in dict.type.vip_birthday_type"
                        :key="dict.value"
                        :value="dict.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <el-form-item label="参与条件">
                    <div class="x-x">
                      <el-checkbox v-model="form.checked">每次抽奖消耗</el-checkbox>
                      <el-form-item v-if="!form.checked">
                        <el-input class="Input" disabled></el-input>
                        积分
                      </el-form-item>
                      <el-form-item v-if="form.checked">
                        <el-input class="Input"></el-input>
                        积分
                      </el-form-item>
                    </div>
                  </el-form-item>
                  <el-form-item label="抽奖次数">
                    <div class="x-x">
                      <div class="x-x">
                        <el-checkbox v-model="form.checked">每人每天抽</el-checkbox>
                        <el-form-item v-if="!form.checked">
                          <el-input class="Input" disabled></el-input>
                          次
                        </el-form-item>
                        <el-form-item v-if="form.checked">
                          <el-input class="Input"></el-input>
                          次
                        </el-form-item>
                      </div>
                      <div class="x-x marL15">
                        <el-checkbox v-model="form.checked">每人一共抽</el-checkbox>
                        <el-form-item v-if="!form.checked">
                          <el-input class="Input" disabled></el-input>
                          次
                        </el-form-item>
                        <el-form-item v-if="form.checked">
                          <el-input class="Input"></el-input>
                          次
                        </el-form-item>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item label="中奖次数">
                    <div class="x-f">
                      <el-radio-group v-model="form.radio">
                        <el-radio :label="3">无限制</el-radio>
                        <el-radio :label="6">每人最多抽中</el-radio>
                      </el-radio-group>
                      <el-form-item v-if="form.radio === 3">
                        <el-input class="Input" disabled></el-input>
                        次
                      </el-form-item>
                      <el-form-item v-if="form.radio === 6">
                        <el-input class="Input"></el-input>
                        次
                      </el-form-item>
                    </div>
                  </el-form-item>
                  <el-form-item label="额外抽奖机会">
                    <div class="x-x">
                      <div class="x-f">
                        <el-checkbox v-model="form.checked">下单获得</el-checkbox>
                        <div class="tag marL10">(每支付完成1笔订单,可获得1次)</div>
                      </div>
                      <!-- <div class="x-f marL15">
                        <el-checkbox v-model="form.checked">分享获得</el-checkbox>
                        <div class="tag marL10">(每分享1次,可获得1次)</div>
                      </div> -->
                    </div>
                  </el-form-item>
                  <el-form-item label="参与送积分">
                    <div class="x-x">
                      <el-checkbox v-model="form.checked">参与抽奖送</el-checkbox>
                      <el-form-item v-if="!form.checked">
                        <el-input class="Input" disabled></el-input>
                        积分
                      </el-form-item>
                      <el-form-item v-if="form.checked">
                        <el-input class="Input"></el-input>
                        积分
                      </el-form-item>
                    </div>
                    <div class="x-x">
                      <el-checkbox v-model="form.checked">仅送给未中奖用户</el-checkbox>
                    </div>
                  </el-form-item>
                </div>
              </template>
            </comCard>
            <!-- 奖品设置 -->
            <comCard :title="`奖品设置`">
              <template #content>
                <el-form-item label="中奖名单">
                  <el-radio-group v-model="form.radio">
                    <el-radio :label="3">展示</el-radio>
                    <el-radio :label="6">不展示</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="玩法规则">
                  <el-input
                    style="width: 230px"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    placeholder="请输入内容"
                    v-model="textarea2"
                  >
                  </el-input>
                </el-form-item>
              </template>
            </comCard>

            <!-- 奖品设置 -->
            <comCard :title="`奖品设置`">
              <template #content>
                <div class="prizeSet">
                  <div class="addPrize x-f">
                    <div class="addBtn" @click="OpenPrize = true">添加奖品</div>
                    <div class="num">(0/7)</div>
                  </div>
                  <!-- <el-table
                    class="table"
                    ref="Table"
                    :data="prizeSetList"
                    row-key="columnId"
                    max-height="300"
                    border
                    :key="iskey"
                  >
                    <el-table-column
                      label="序号"
                      type="index"
                      width="80"
                      align="center"
                    />
                    <el-table-column align="center" label="操作" width="120">
                      <template v-slot="scope">
                        <i class="el-icon-circle-plus operatePush"></i>
                        <i class="el-icon-remove operateDel"></i>
                      </template>
                    </el-table-column>
                    <el-table-column label="名称" align="center" prop="goodsNo">
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        名称
                      </template>
                      <template slot-scope="scope">
                        <el-form-item>
                          <el-input v-model="scope.row.goodsNo"> </el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="奖品类型"
                      align="center"
                      prop="goodsNo"
                    >
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        奖品类型
                      </template>
                      <template slot-scope="scope">
                        <el-form-item>
                          <el-input v-model="scope.row.goodsNo"> </el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="发放总量"
                      align="center"
                      prop="goodsNo"
                    >
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        发放总量
                      </template>
                      <template slot-scope="scope">
                        <el-form-item>
                          <el-input v-model="scope.row.goodsNo"> </el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="已发放"
                      align="center"
                      prop="goodsNo"
                    >
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        已发放
                      </template>
                      <template slot-scope="scope">
                        <el-form-item>
                          <el-input v-model="scope.row.goodsNo"> </el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="转盘图片"
                      align="center"
                      prop="goodsNo"
                      width="100"
                    >
                      <template slot="header">
                        <i style="color: #ff4949">*</i>
                        转盘图片
                      </template>
                      <template slot-scope="scope">
                        <el-form-item>
                          <el-upload
                            class="avatar-uploader"
                            :action="uploadUrl"
                            :show-file-list="false"
                            :on-success="handleLogoSuccess"
                            :before-upload="beforeAvatarUpload"
                          >
                            <img
                              v-if="form.goodsUrl"
                              :src="form.goodsUrl"
                              class="avatar"
                            />
                            <i
                              v-else
                              class="el-icon-plus avatar-uploader-icon"
                            ></i>
                            <div v-if="form.goodsUrl" class="updateImg">
                              更换图片
                            </div>
                          </el-upload>
                        </el-form-item>
                      </template>
                    </el-table-column>
                  </el-table> -->
                </div>
              </template>
            </comCard>
            <!-- 未中奖设置 -->
            <comCard :title="`未中奖设置`">
              <template #content>
                <div class="noPrizeSet">
                  <el-form-item label="名称">
                    <el-input></el-input>
                  </el-form-item>
                  <el-form-item label="图片" class="noPrizeSetImg">
                    <div class="x-end">
                      <el-upload
                        class="avatar-uploader x-x"
                        :action="uploadUrl"
                        :show-file-list="false"
                        :on-success="handleLogoSuccess"
                        :before-upload="beforeAvatarUpload"
                      >
                        <img v-if="form.goodsUrl" :src="form.goodsUrl" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <div v-if="form.goodsUrl" class="updateImg">更换图片</div>
                      </el-upload>
                      <div class="marL10 more">恢复默认图</div>
                    </div>
                    <div class="tag">(建议尺寸100*100px,支持jpg/png/jpeg格式)</div>
                  </el-form-item>
                  <el-form-item label="提示语">
                    <el-input></el-input>
                  </el-form-item>
                  <el-form-item label="提示跳转">
                    <el-radio-group v-model="form.radio">
                      <el-radio :label="3">无跳转</el-radio>
                      <el-radio :label="6">有跳转</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
              </template>
            </comCard>
            <!-- 转盘设置 -->
            <comCard :title="`转盘设置`">
              <template #content>
                <div class="wheelSet">
                  <div class="x-x">
                    <el-table class="table" ref="Table" :data="prizeWheelList" border>
                      <el-table-column
                        label="转盘位置"
                        type="index"
                        width="80"
                        align="center"
                      />
                      <el-table-column
                        label="奖品"
                        align="center"
                        prop="goodsNo"
                        width="280"
                      >
                        <template slot="header">
                          <i style="color: #ff4949">*</i>
                          奖品
                        </template>
                        <template slot-scope="scope">
                          <el-form-item>
                            <el-select v-model="form.value" placeholder="请选择">
                              <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="转盘图片"
                        align="center"
                        prop="goodsNo"
                        width="140"
                      >
                        <template slot="header">
                          <i style="color: #ff4949">*</i>
                          转盘图片
                        </template>
                        <template slot-scope="scope">
                          <el-form-item>
                            <img src="" alt="" />
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="转到概率"
                        align="center"
                        prop="goodsNo"
                        width="200"
                      >
                        <template slot="header">
                          <i style="color: #ff4949">*</i>
                          转到概率
                        </template>
                        <template slot-scope="scope">
                          <el-form-item>
                            <el-input placeholder="请输入" v-model="form.input2">
                              <template slot="append">%</template>
                            </el-input>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column> </el-table-column>
                    </el-table>
                    <!-- 转盘位置示意图 -->
                    <div class="sketchMap">
                      <div class="one">
                        <div>1</div>
                        <div>2</div>
                        <div>3</div>
                      </div>
                      <div class="two">
                        <div>8</div>
                        <div>4</div>
                      </div>
                      <div class="three">
                        <div>7</div>
                        <div>6</div>
                        <div>5</div>
                      </div>
                      <div class="tag">转盘位置示意图</div>
                    </div>
                  </div>
                </div>
              </template>
            </comCard>
          </el-scrollbar>
          <!-- 奖品弹出框 -->
          <PrizeFrame :OpenPrize.sync="OpenPrize"></PrizeFrame>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import PrizeFrame from "./components/PrizeFrame.vue"; //奖品选择框
export default {
  name: "LuckDrawDetail",
  dicts: [
    "vip_birthday_type", //会员生日范围
  ],
  components: { PrizeFrame },
  data() {
    return {
      //奖品弹出框开关
      OpenPrize: false,
      //抽奖转盘默认图片
      defaultPig: require("@/assets/images/luckyDrawIcon.png"),
      form: {
        vipLevelRangeTypeArr: [], //适用对象数组
        radio: 3,
      },
      iskey: false,
      uploadUrl: process.env.VUE_APP_BASE_API + "/api/open/oss/file/upload",
      //奖品设置表格数据
      prizeSetList: [{ id: 1 }],
      //转盘抽奖设置表格数据
      prizeWheelList: [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 },
        { id: 6 },
        { id: 7 },
        { id: 8 },
      ],
      //开启会员生日下拉框开关
      showVipBD: false,
      imageUrl: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
    };
  },
  watch: {
    //侦听适用对象
    "form.vipLevelRangeTypeArr": {
      handler(val) {
        if (val) {
          //当选中全部会员+排除/指定会员时 显示会员表格 反之隐藏
          const bool = val.some((v) => v === "1" || v === "2");
          this.vipTable = bool ? true : false;
          //当选中生日会员  显示会员生日下拉框 反之隐藏
          const vipBD = val.some((v) => v === "4");
          this.showVipBD = vipBD ? true : false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    //选中适用对象改变触发(选中全部会员去除指定会员,选中指定会员去除全部会员)
    handleCheckVip() {
      if (this.form.vipLevelRangeTypeArr.length > 1) {
        // 拿到最后一个选择的数据  要以最后一个选择的为判断
        if (
          this.form.vipLevelRangeTypeArr[this.form.vipLevelRangeTypeArr.length - 1] ===
          "2"
        ) {
          //当最后一个数据是指定会员 去除全部会员/全部会员+排除
          this.form.vipLevelRangeTypeArr = this.form.vipLevelRangeTypeArr.filter(
            (v) => v !== "0" && v !== "1"
          );
        } else if (
          this.form.vipLevelRangeTypeArr[this.form.vipLevelRangeTypeArr.length - 1] ===
          "0"
        ) {
          //当最后一个数据是全部会员 去除指定会员/全部会员+排除/会员生日
          this.form.vipLevelRangeTypeArr = this.form.vipLevelRangeTypeArr.filter(
            (v) => v !== "2" && v !== "4" && v !== "1"
          );
        } else if (
          this.form.vipLevelRangeTypeArr[this.form.vipLevelRangeTypeArr.length - 1] ===
          "1"
        ) {
          //当最后一个数据是全部会员+排除 去除指定会员/全部会员/会员生日
          this.form.vipLevelRangeTypeArr = this.form.vipLevelRangeTypeArr.filter(
            (v) => v !== "2" && v !== "4" && v !== "0"
          );
        } else if (
          this.form.vipLevelRangeTypeArr[this.form.vipLevelRangeTypeArr.length - 1] ===
          "4"
        ) {
          //当最后一个数据是会员生日 去除全部会员/全部会员+排除
          this.form.vipLevelRangeTypeArr = this.form.vipLevelRangeTypeArr.filter(
            (v) => v !== "0" && v !== "1"
          );
        }
      }
    },
    /** 图片上传之前的尺寸校验 */
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      return isLt2M;
    },
    /** 上传LOGO图片方式 */
    handleLogoSuccess(res, file) {
      this.form.goodsUrl = file.response.data.accessUrl;
      this.iskey = !this.iskey;
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.luckDrawDetail {
  background-color: #eaeaea;
  .main {
    padding-top: 50px;
    display: flex;
    .leftImg {
      // width: 420px;
      flex: 1;
      background-color: #fff;
      margin: 0 10px;
      margin-right: 0;
      text-align: center;
      //抽奖图片
      .pig {
        display: flex;
        margin-left: 20px;
        width: 375px;
        height: 667px;
        background: url(~@/assets/images/luckyDrawBig.png) no-repeat;
        position: relative;
        //转盘盒子
        .turnplate {
          position: absolute;
          left: 30px;
          top: 200px;
          width: 310px;
          height: 310px;
          // background-color: #fff;
          .one,
          .three {
            display: flex;
            justify-content: space-around;
            div {
              width: 94px;
              height: 101px;
              padding-top: 15px;
              background-color: pink;
              background: url(~@/assets/images/luckyDrawbg.png) no-repeat;
              img {
                width: 60px;
                height: 50px;
              }
              .name {
                height: 20px;
                padding-top: 3px;
                font-size: 14px;
              }
            }
          }
          .two {
            display: flex;
            justify-content: space-between;
            margin: 2px 0;
            padding: 0 5px;
            div {
              width: 94px;
              height: 101px;
              padding-top: 15px;
              background-color: pink;
              background: url(~@/assets/images/luckyDrawbg.png) no-repeat;
              img {
                width: 60px;
                height: 50px;
              }
              .name {
                padding-top: 3px;
                height: 20px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .rightInfo {
      // width: 100%;
      flex: 3;
      .el-scrollbar {
        overflow: hidden;
        height: 800px;
        //上传图片样式
        .avatar-uploader {
          width: 48px;
          height: 48px;
          margin-left: 15px;
          position: relative;
        }
        .avatar-uploader .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
          border-color: #409eff;
        }
        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 48px;
          height: 48px;
          line-height: 48px;
          text-align: center;
          border: 1px dashed #d9d9d9;
        }
        .avatar {
          width: 48px;
          height: 48px;
          display: block;
        }
        .updateImg {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 999;
          font-size: 12px;
          line-height: 20px;
          background-color: rgba(0, 0, 0, 0.2);
          color: #fff;
        }
        //基本资料区域
        .basicInfo {
          padding: 0 10px;
          .el-input {
            width: 410px;
          }
          ::v-deep .el-date-editor--datetimerange.el-input__inner {
            width: 410px;
          }
          //会员生日下拉框宽度
          .selectVipBD {
            margin-left: 5px;
            width: 140px;
          }
          //输入框宽度
          .Input {
            width: 100px;
            margin-left: 5px;
          }
        }
        //每一行的间距
        .el-form-item {
          margin-bottom: 10px;
        }
        //奖品设置表格区域
        .prizeSet {
          padding: 0 10px 10px 10px;
          //新增按钮
          .addPrize {
            font-size: 16px;
            .addBtn {
              color: #409eff;
              cursor: pointer;
            }
            .num {
              color: #a4a5a6;
              margin: 0 5px;
            }
          }
          //使表格校验时显示，校验通过时隐藏
          ::v-deep .el-form-item {
            padding: 0;
            margin: 0;
          }
          ::v-deep .el-form-item__error {
            position: static;
          }
          ::v-deep .el-form-item__content {
            margin-left: 0 !important;
          }
        }
        //未中奖设置区域
        .noPrizeSet {
          .el-input {
            width: 200px;
          }
        }
      }
      //转盘设置区域
      .wheelSet {
        padding: 0 10px;
        //使表格校验时显示，校验通过时隐藏
        ::v-deep .el-form-item {
          padding: 0;
          margin: 0;
        }
        ::v-deep .el-form-item__error {
          position: static;
        }
        ::v-deep .el-form-item__content {
          margin-left: 0 !important;
        }
        .el-select {
          width: 100%;
        }
        //转盘位置示意图区域
        .sketchMap {
          width: 220px;
          height: 260px;
          margin-left: 30px;
          margin-right: 100px;
          .one,
          .three {
            display: flex;
            justify-content: space-around;
            div {
              width: 70px;
              height: 70px;
              background-color: #f2f3f5;
              text-align: center;
              line-height: 70px;
              color: #c4c5c7;
              font-weight: 700;
              font-size: 30px;
            }
          }
          .two {
            display: flex;
            justify-content: space-between;
            margin: 5px 2px;
            div {
              width: 70px;
              height: 70px;
              background-color: #f2f3f5;
              text-align: center;
              line-height: 70px;
              color: #c4c5c7;
              font-weight: 700;
              font-size: 30px;
            }
          }
          .tag {
            text-align: center;
            line-height: 40px;
            color: #9c9d9f;
          }
        }
      }
      //提示的文字
      .tag {
        margin-bottom: 0;
        color: #a4a5a6;
        line-height: 25px;
      }
    }
  }
}
</style>
