<template>
  <!-- 抽奖活动 -->
  <div class="luckDraw">
    <el-card class="box-card">
      <!-- 头部区域 -->
      <div class="header">
        <!-- 搜索区域 -->
        <searchTitle :isHighSearch="true">
          <template #selectTop>
            <el-form-item label-width="0px">
              <el-input
                class="searchInput"
                placeholder="请搜索活动编号/活动名称"
                prefix-icon="el-icon-search"
                v-model="selectVal"
                size="small"
              ></el-input>
            </el-form-item>
          </template>
        </searchTitle>
        <!-- 按钮区域 -->
        <comBtn @clickBtn="clickBtn"></comBtn>
      </div>
      <!-- 表格区域 -->
      <div class="table">
        <!-- 表格数据 -->
        <el-table
          v-loading="loading"
          :data="seckillList"
          @selection-change="handleSelectionChange"
          border
          height="605"
        >
          <el-table-column
            type="selection"
            width="50"
            align="center"
            fixed="left"
          />
          <el-table-column
            type="index"
            width="50"
            align="center"
            label="序号"
            fixed="left"
          />
          <el-table-column
            label="活动编号"
            align="center"
            key="categoryNo"
            prop="categoryNo"
          >
            <template slot-scope="scope">
              <el-button type="text">{{ scope.row.categoryNo }}</el-button>
            </template>
          </el-table-column>
          <el-table-column
            label="活动名称"
            align="center"
            key="categoryName"
            prop="categoryName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="活动状态"
            align="center"
            key="remark"
            prop="categoryName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="执行状态"
            align="center"
            key="sortNo"
            prop="sortNo"
            show-overflow-tooltip
          />
          <el-table-column
            label="启用状态"
            align="center"
            key="remark"
            prop="categoryName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="生效时间"
            align="center"
            key="remark"
            prop="categoryName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="失效时间"
            align="center"
            key="remark"
            prop="categoryName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="抽奖次数"
            align="center"
            key="remark"
            prop="categoryName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="抽中次数"
            align="center"
            key="remark"
            prop="categoryName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="备注"
            align="center"
            key="remark"
            prop="categoryName"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="创建人"
            align="center"
            key="createBy"
            prop="createBy"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="创建时间"
            align="center"
            key="createBy"
            prop="createBy"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="修改人"
            align="center"
            key="updateBy"
            prop="updateBy"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="修改时间"
            align="center"
            key="createBy"
            prop="createBy"
            :show-overflow-tooltip="true"
          />
        </el-table>
        <!-- 分页 -->
        <!-- <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />-->
      </div>
    </el-card>
  </div>
</template>

<script>
import searchTitle from '@/views/components/searchTitle' //搜索区域组件
import comBtn from '@/views/components/com-btn' //按钮区域组件
export default {
  name: 'LuckDraw',
  components: {
    searchTitle,
    comBtn
  },
  data () {
    return {
      selectVal: undefined, //搜索框
      loading: false, //遮罩层
      seckillList: [] //团购列表数据
    }
  },
  methods: {
    // 表格多选框选中数据
    handleSelectionChange (selection) {},
    //点击按钮事件 type参数区分类型(新增/审核/启用/复制/删除/刷新)
    clickBtn (type) {
      console.log(type)
      if (type === '新增') {
        this.$router.push({
          name: 'LuckDrawDetail'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.luckDraw {
  padding: 10px;
  background-color: #f3f3f3;
  .el-card {
    height: 820px;
    //搜索框区域
    .searchInput {
      width: 320px;
    }
  }
}
</style>
