var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "luckDrawDetail" },
    [
      _c("head-tool", {
        scopedSlots: _vm._u([
          {
            key: "right",
            fn: function () {
              return [
                _c("el-button", { attrs: { type: "primary", size: "mini" } }, [
                  _vm._v("保存"),
                ]),
                _c("el-button", { attrs: { type: "primary", size: "mini" } }, [
                  _vm._v("保存并退出"),
                ]),
                _c(
                  "el-dropdown",
                  { attrs: { "split-button": "", size: "mini" } },
                  [
                    _vm._v(" 审核 "),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [_c("el-dropdown-item", [_vm._v("反审核")])],
                      1
                    ),
                  ],
                  1
                ),
                _c("el-button", { attrs: { size: "mini" } }, [_vm._v("退出")]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("el-form", { attrs: { "label-width": "100px" } }, [
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "leftImg" }, [
            _c("h3", [_vm._v("抽奖样式")]),
            _c("div", { staticClass: "pig" }, [
              _c("div", { staticClass: "turnplate" }, [
                _c("div", { staticClass: "one" }, [
                  _c("div", [
                    _c("img", {
                      attrs: { src: _vm.defaultPig, alt: "加载失败" },
                    }),
                    _c("div", { staticClass: "name" }, [_vm._v("111")]),
                  ]),
                  _c("div", [
                    _c("img", {
                      attrs: { src: _vm.defaultPig, alt: "加载失败" },
                    }),
                    _c("div", { staticClass: "name" }, [_vm._v("111")]),
                  ]),
                  _c("div", [
                    _c("img", {
                      attrs: { src: _vm.defaultPig, alt: "加载失败" },
                    }),
                    _c("div", { staticClass: "name" }, [_vm._v("111")]),
                  ]),
                ]),
                _c("div", { staticClass: "two" }, [
                  _c("div", [
                    _c("img", {
                      attrs: { src: _vm.defaultPig, alt: "加载失败" },
                    }),
                    _c("div", { staticClass: "name" }, [_vm._v("111")]),
                  ]),
                  _c("div", [
                    _c("img", {
                      attrs: { src: _vm.defaultPig, alt: "加载失败" },
                    }),
                    _c("div", { staticClass: "name" }, [_vm._v("111")]),
                  ]),
                ]),
                _c("div", { staticClass: "three" }, [
                  _c("div", [
                    _c("img", {
                      attrs: { src: _vm.defaultPig, alt: "加载失败" },
                    }),
                    _c("div", { staticClass: "name" }, [_vm._v("111")]),
                  ]),
                  _c("div", [
                    _c("img", {
                      attrs: { src: _vm.defaultPig, alt: "加载失败" },
                    }),
                    _c("div", { staticClass: "name" }, [_vm._v("111")]),
                  ]),
                  _c("div", [
                    _c("img", {
                      attrs: { src: _vm.defaultPig, alt: "加载失败" },
                    }),
                    _c("div", { staticClass: "name" }, [_vm._v("111")]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "rightInfo" },
            [
              _c(
                "el-scrollbar",
                [
                  _c("comCard", {
                    attrs: { title: "基本信息" },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "basicInfo" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "活动编号",
                                      prop: "promoNo",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: "",
                                        placeholder: "自动生成",
                                      },
                                      model: {
                                        value: _vm.form.promoNo,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "promoNo", $$v)
                                        },
                                        expression: "form.promoNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "活动名称",
                                      prop: "promoName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.form.promoName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "promoName", $$v)
                                        },
                                        expression: "form.promoName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "活动日期",
                                          prop: "datetime",
                                        },
                                      },
                                      [
                                        _c("el-date-picker", {
                                          attrs: {
                                            type: "datetimerange",
                                            "range-separator": "至",
                                            "start-placeholder": "开始日期",
                                            "end-placeholder": "结束日期",
                                          },
                                          model: {
                                            value: _vm.form.datetime,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "datetime",
                                                $$v
                                              )
                                            },
                                            expression: "form.datetime",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "x-x" },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "适用对象",
                                          prop: "vipLevelRangeTypeArr",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-checkbox-group",
                                          {
                                            on: { change: _vm.handleCheckVip },
                                            model: {
                                              value:
                                                _vm.form.vipLevelRangeTypeArr,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "vipLevelRangeTypeArr",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.vipLevelRangeTypeArr",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-checkbox",
                                              { attrs: { label: "0" } },
                                              [_vm._v("全部会员")]
                                            ),
                                            _c(
                                              "el-checkbox",
                                              { attrs: { label: "3" } },
                                              [_vm._v("非会员")]
                                            ),
                                            _c(
                                              "el-checkbox",
                                              { attrs: { label: "1" } },
                                              [_vm._v("全部会员+排除")]
                                            ),
                                            _c(
                                              "el-checkbox",
                                              { attrs: { label: "2" } },
                                              [_vm._v("指定会员")]
                                            ),
                                            _c(
                                              "el-checkbox",
                                              { attrs: { label: "4" } },
                                              [_vm._v("生日会员")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.showVipBD
                                      ? _c(
                                          "el-select",
                                          {
                                            staticClass: "selectVipBD",
                                            model: {
                                              value: _vm.form.vipBirthdayType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "vipBirthdayType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.vipBirthdayType",
                                            },
                                          },
                                          _vm._l(
                                            _vm.dict.type.vip_birthday_type,
                                            function (dict) {
                                              return _c("el-option", {
                                                key: dict.value,
                                                attrs: {
                                                  label: dict.label,
                                                  value: dict.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "参与条件" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "x-x" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            model: {
                                              value: _vm.form.checked,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "checked",
                                                  $$v
                                                )
                                              },
                                              expression: "form.checked",
                                            },
                                          },
                                          [_vm._v("每次抽奖消耗")]
                                        ),
                                        !_vm.form.checked
                                          ? _c(
                                              "el-form-item",
                                              [
                                                _c("el-input", {
                                                  staticClass: "Input",
                                                  attrs: { disabled: "" },
                                                }),
                                                _vm._v(" 积分 "),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.form.checked
                                          ? _c(
                                              "el-form-item",
                                              [
                                                _c("el-input", {
                                                  staticClass: "Input",
                                                }),
                                                _vm._v(" 积分 "),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "抽奖次数" } },
                                  [
                                    _c("div", { staticClass: "x-x" }, [
                                      _c(
                                        "div",
                                        { staticClass: "x-x" },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              model: {
                                                value: _vm.form.checked,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "checked",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.checked",
                                              },
                                            },
                                            [_vm._v("每人每天抽")]
                                          ),
                                          !_vm.form.checked
                                            ? _c(
                                                "el-form-item",
                                                [
                                                  _c("el-input", {
                                                    staticClass: "Input",
                                                    attrs: { disabled: "" },
                                                  }),
                                                  _vm._v(" 次 "),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.form.checked
                                            ? _c(
                                                "el-form-item",
                                                [
                                                  _c("el-input", {
                                                    staticClass: "Input",
                                                  }),
                                                  _vm._v(" 次 "),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "x-x marL15" },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              model: {
                                                value: _vm.form.checked,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "checked",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.checked",
                                              },
                                            },
                                            [_vm._v("每人一共抽")]
                                          ),
                                          !_vm.form.checked
                                            ? _c(
                                                "el-form-item",
                                                [
                                                  _c("el-input", {
                                                    staticClass: "Input",
                                                    attrs: { disabled: "" },
                                                  }),
                                                  _vm._v(" 次 "),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.form.checked
                                            ? _c(
                                                "el-form-item",
                                                [
                                                  _c("el-input", {
                                                    staticClass: "Input",
                                                  }),
                                                  _vm._v(" 次 "),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "中奖次数" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "x-f" },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            model: {
                                              value: _vm.form.radio,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "radio", $$v)
                                              },
                                              expression: "form.radio",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 3 } },
                                              [_vm._v("无限制")]
                                            ),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 6 } },
                                              [_vm._v("每人最多抽中")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.form.radio === 3
                                          ? _c(
                                              "el-form-item",
                                              [
                                                _c("el-input", {
                                                  staticClass: "Input",
                                                  attrs: { disabled: "" },
                                                }),
                                                _vm._v(" 次 "),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.form.radio === 6
                                          ? _c(
                                              "el-form-item",
                                              [
                                                _c("el-input", {
                                                  staticClass: "Input",
                                                }),
                                                _vm._v(" 次 "),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "额外抽奖机会" } },
                                  [
                                    _c("div", { staticClass: "x-x" }, [
                                      _c(
                                        "div",
                                        { staticClass: "x-f" },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              model: {
                                                value: _vm.form.checked,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "checked",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.checked",
                                              },
                                            },
                                            [_vm._v("下单获得")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "tag marL10" },
                                            [
                                              _vm._v(
                                                "(每支付完成1笔订单,可获得1次)"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "参与送积分" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "x-x" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            model: {
                                              value: _vm.form.checked,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "checked",
                                                  $$v
                                                )
                                              },
                                              expression: "form.checked",
                                            },
                                          },
                                          [_vm._v("参与抽奖送")]
                                        ),
                                        !_vm.form.checked
                                          ? _c(
                                              "el-form-item",
                                              [
                                                _c("el-input", {
                                                  staticClass: "Input",
                                                  attrs: { disabled: "" },
                                                }),
                                                _vm._v(" 积分 "),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.form.checked
                                          ? _c(
                                              "el-form-item",
                                              [
                                                _c("el-input", {
                                                  staticClass: "Input",
                                                }),
                                                _vm._v(" 积分 "),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "x-x" },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            model: {
                                              value: _vm.form.checked,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "checked",
                                                  $$v
                                                )
                                              },
                                              expression: "form.checked",
                                            },
                                          },
                                          [_vm._v("仅送给未中奖用户")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("comCard", {
                    attrs: { title: "奖品设置" },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c(
                              "el-form-item",
                              { attrs: { label: "中奖名单" } },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.form.radio,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "radio", $$v)
                                      },
                                      expression: "form.radio",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 3 } }, [
                                      _vm._v("展示"),
                                    ]),
                                    _c("el-radio", { attrs: { label: 6 } }, [
                                      _vm._v("不展示"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "玩法规则" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "230px" },
                                  attrs: {
                                    type: "textarea",
                                    autosize: { minRows: 2, maxRows: 4 },
                                    placeholder: "请输入内容",
                                  },
                                  model: {
                                    value: _vm.textarea2,
                                    callback: function ($$v) {
                                      _vm.textarea2 = $$v
                                    },
                                    expression: "textarea2",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("comCard", {
                    attrs: { title: "奖品设置" },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "prizeSet" }, [
                              _c("div", { staticClass: "addPrize x-f" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "addBtn",
                                    on: {
                                      click: function ($event) {
                                        _vm.OpenPrize = true
                                      },
                                    },
                                  },
                                  [_vm._v("添加奖品")]
                                ),
                                _c("div", { staticClass: "num" }, [
                                  _vm._v("(0/7)"),
                                ]),
                              ]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("comCard", {
                    attrs: { title: "未中奖设置" },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "noPrizeSet" },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "名称" } },
                                  [_c("el-input")],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "noPrizeSetImg",
                                    attrs: { label: "图片" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "x-end" },
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "avatar-uploader x-x",
                                            attrs: {
                                              action: _vm.uploadUrl,
                                              "show-file-list": false,
                                              "on-success":
                                                _vm.handleLogoSuccess,
                                              "before-upload":
                                                _vm.beforeAvatarUpload,
                                            },
                                          },
                                          [
                                            _vm.form.goodsUrl
                                              ? _c("img", {
                                                  staticClass: "avatar",
                                                  attrs: {
                                                    src: _vm.form.goodsUrl,
                                                  },
                                                })
                                              : _c("i", {
                                                  staticClass:
                                                    "el-icon-plus avatar-uploader-icon",
                                                }),
                                            _vm.form.goodsUrl
                                              ? _c(
                                                  "div",
                                                  { staticClass: "updateImg" },
                                                  [_vm._v("更换图片")]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "marL10 more" },
                                          [_vm._v("恢复默认图")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "tag" }, [
                                      _vm._v(
                                        "(建议尺寸100*100px,支持jpg/png/jpeg格式)"
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "提示语" } },
                                  [_c("el-input")],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "提示跳转" } },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: _vm.form.radio,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "radio", $$v)
                                          },
                                          expression: "form.radio",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 3 } },
                                          [_vm._v("无跳转")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: 6 } },
                                          [_vm._v("有跳转")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("comCard", {
                    attrs: { title: "转盘设置" },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "wheelSet" }, [
                              _c(
                                "div",
                                { staticClass: "x-x" },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      ref: "Table",
                                      staticClass: "table",
                                      attrs: {
                                        data: _vm.prizeWheelList,
                                        border: "",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "转盘位置",
                                          type: "index",
                                          width: "80",
                                          align: "center",
                                        },
                                      }),
                                      _c(
                                        "el-table-column",
                                        {
                                          attrs: {
                                            label: "奖品",
                                            align: "center",
                                            prop: "goodsNo",
                                            width: "280",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-form-item",
                                                    [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          attrs: {
                                                            placeholder:
                                                              "请选择",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.value",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.options,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.value,
                                                                attrs: {
                                                                  label:
                                                                    item.label,
                                                                  value:
                                                                    item.value,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c("template", { slot: "header" }, [
                                            _c(
                                              "i",
                                              {
                                                staticStyle: {
                                                  color: "#ff4949",
                                                },
                                              },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v(" 奖品 "),
                                          ]),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "el-table-column",
                                        {
                                          attrs: {
                                            label: "转盘图片",
                                            align: "center",
                                            prop: "goodsNo",
                                            width: "140",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-form-item", [
                                                    _c("img", {
                                                      attrs: {
                                                        src: "",
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c("template", { slot: "header" }, [
                                            _c(
                                              "i",
                                              {
                                                staticStyle: {
                                                  color: "#ff4949",
                                                },
                                              },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v(" 转盘图片 "),
                                          ]),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "el-table-column",
                                        {
                                          attrs: {
                                            label: "转到概率",
                                            align: "center",
                                            prop: "goodsNo",
                                            width: "200",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-form-item",
                                                    [
                                                      _c(
                                                        "el-input",
                                                        {
                                                          attrs: {
                                                            placeholder:
                                                              "请输入",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.form.input2,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.form,
                                                                "input2",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "form.input2",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "append" },
                                                            [_vm._v("%")]
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c("template", { slot: "header" }, [
                                            _c(
                                              "i",
                                              {
                                                staticStyle: {
                                                  color: "#ff4949",
                                                },
                                              },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v(" 转到概率 "),
                                          ]),
                                        ],
                                        2
                                      ),
                                      _c("el-table-column"),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "sketchMap" }, [
                                    _c("div", { staticClass: "one" }, [
                                      _c("div", [_vm._v("1")]),
                                      _c("div", [_vm._v("2")]),
                                      _c("div", [_vm._v("3")]),
                                    ]),
                                    _c("div", { staticClass: "two" }, [
                                      _c("div", [_vm._v("8")]),
                                      _c("div", [_vm._v("4")]),
                                    ]),
                                    _c("div", { staticClass: "three" }, [
                                      _c("div", [_vm._v("7")]),
                                      _c("div", [_vm._v("6")]),
                                      _c("div", [_vm._v("5")]),
                                    ]),
                                    _c("div", { staticClass: "tag" }, [
                                      _vm._v("转盘位置示意图"),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("PrizeFrame", {
                attrs: { OpenPrize: _vm.OpenPrize },
                on: {
                  "update:OpenPrize": function ($event) {
                    _vm.OpenPrize = $event
                  },
                  "update:open-prize": function ($event) {
                    _vm.OpenPrize = $event
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }