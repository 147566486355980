var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "luckDraw" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          { staticClass: "header" },
          [
            _c("searchTitle", {
              attrs: { isHighSearch: true },
              scopedSlots: _vm._u([
                {
                  key: "selectTop",
                  fn: function () {
                    return [
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "0px" } },
                        [
                          _c("el-input", {
                            staticClass: "searchInput",
                            attrs: {
                              placeholder: "请搜索活动编号/活动名称",
                              "prefix-icon": "el-icon-search",
                              size: "small",
                            },
                            model: {
                              value: _vm.selectVal,
                              callback: function ($$v) {
                                _vm.selectVal = $$v
                              },
                              expression: "selectVal",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
            _c("comBtn", { on: { clickBtn: _vm.clickBtn } }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                attrs: { data: _vm.seckillList, border: "", height: "605" },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    type: "selection",
                    width: "50",
                    align: "center",
                    fixed: "left",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    width: "50",
                    align: "center",
                    label: "序号",
                    fixed: "left",
                  },
                }),
                _c("el-table-column", {
                  key: "categoryNo",
                  attrs: {
                    label: "活动编号",
                    align: "center",
                    prop: "categoryNo",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("el-button", { attrs: { type: "text" } }, [
                            _vm._v(_vm._s(scope.row.categoryNo)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  key: "categoryName",
                  attrs: {
                    label: "活动名称",
                    align: "center",
                    prop: "categoryName",
                    "show-overflow-tooltip": true,
                  },
                }),
                _c("el-table-column", {
                  key: "remark",
                  attrs: {
                    label: "活动状态",
                    align: "center",
                    prop: "categoryName",
                    "show-overflow-tooltip": true,
                  },
                }),
                _c("el-table-column", {
                  key: "sortNo",
                  attrs: {
                    label: "执行状态",
                    align: "center",
                    prop: "sortNo",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  key: "remark",
                  attrs: {
                    label: "启用状态",
                    align: "center",
                    prop: "categoryName",
                    "show-overflow-tooltip": true,
                  },
                }),
                _c("el-table-column", {
                  key: "remark",
                  attrs: {
                    label: "生效时间",
                    align: "center",
                    prop: "categoryName",
                    "show-overflow-tooltip": true,
                  },
                }),
                _c("el-table-column", {
                  key: "remark",
                  attrs: {
                    label: "失效时间",
                    align: "center",
                    prop: "categoryName",
                    "show-overflow-tooltip": true,
                  },
                }),
                _c("el-table-column", {
                  key: "remark",
                  attrs: {
                    label: "抽奖次数",
                    align: "center",
                    prop: "categoryName",
                    "show-overflow-tooltip": true,
                  },
                }),
                _c("el-table-column", {
                  key: "remark",
                  attrs: {
                    label: "抽中次数",
                    align: "center",
                    prop: "categoryName",
                    "show-overflow-tooltip": true,
                  },
                }),
                _c("el-table-column", {
                  key: "remark",
                  attrs: {
                    label: "备注",
                    align: "center",
                    prop: "categoryName",
                    "show-overflow-tooltip": true,
                  },
                }),
                _c("el-table-column", {
                  key: "createBy",
                  attrs: {
                    label: "创建人",
                    align: "center",
                    prop: "createBy",
                    "show-overflow-tooltip": true,
                  },
                }),
                _c("el-table-column", {
                  key: "createBy",
                  attrs: {
                    label: "创建时间",
                    align: "center",
                    prop: "createBy",
                    "show-overflow-tooltip": true,
                  },
                }),
                _c("el-table-column", {
                  key: "updateBy",
                  attrs: {
                    label: "修改人",
                    align: "center",
                    prop: "updateBy",
                    "show-overflow-tooltip": true,
                  },
                }),
                _c("el-table-column", {
                  key: "createBy",
                  attrs: {
                    label: "修改时间",
                    align: "center",
                    prop: "createBy",
                    "show-overflow-tooltip": true,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }